import { ReactNode } from 'react';
import { Asset } from '@he-novation/config/types/asset.types';
import { FileStatus } from '@he-novation/config/types/db/enums';

export enum FrontFolderContentFileStatus {
    COPYING = 'COPYING',
    WAITING_FOR_UPLOAD = 'WAITING_FOR_UPLOAD',
    UPLOADING = 'UPLOADING',
    WAITING_FOR_TRANSCODE = 'WAITING_FOR_TRANSCODE',
    TRANSCODING = 'TRANSCODING'
}

export type FrontFolderContentFileWaitingForUpload = {
    status: FrontFolderContentFileStatus.WAITING_FOR_UPLOAD;
};

export type FrontFolderContentFileUploading = {
    status: FrontFolderContentFileStatus.UPLOADING;
    uploadProgress: number;
};

export type FrontFolderContentFileWaitingForTranscode = {
    status: FrontFolderContentFileStatus.WAITING_FOR_TRANSCODE;
};

export type FrontFolderContentFileTranscoding = {
    status: FrontFolderContentFileStatus.TRANSCODING;
    remainingTranscodingMs: number;
};

export type FrontFolderContentFileTranscodingCopying = {
    status: FrontFolderContentFileStatus.COPYING;
};

export type FrontFolderContentFileProcessingStatus =
    | FrontFolderContentFileWaitingForUpload
    | FrontFolderContentFileUploading
    | FrontFolderContentFileWaitingForTranscode
    | FrontFolderContentFileTranscoding
    | FrontFolderContentFileTranscodingCopying;

export type FrontFolderContentFile = {
    uuid: string;
    type: 'file';
    name: string;
    notes: number;
    version: number;
    versions: {
        version: number;
        expired: boolean;
    }[];
    thumbnail?: string;
    preview?: string;
    mimeType: string;
    size: number;
    status: FileStatus;
    processingStatus?: FrontFolderContentFileProcessingStatus;
    isPlayable: boolean;
    casts: number;
    created: Date;
    updated: Date;
    duration?: number;
    resolution?: [number, number];
    noAssets?: boolean;
    vantagePath?: string;
    purged?: boolean;
    uploadError?: boolean;
    finals: { uuid: string; email: string }[];
    isBundle: boolean;
    creator: {
        email: string;
        firstname?: string | null;
        lastname?: string | null;
    };
    proxies: FrontFolderContentFileProxy[];
};

export type FrontFolderContentFileFilter = {
    key: keyof FrontFolderContentFile;
    label: ReactNode;
    value: unknown;
};

export type FrontFolderContentFileProxy = Pick<Asset, 'quality' | 'url'>;
